import React,  { useState }  from "react";

const todo_input_labels = [
  "Take Notes!",
  "What's new?",
  "Something to do?",
  "Anything you want",
  "Register It!",
];
const todo_input_labels_index = parseInt(Math.random()* todo_input_labels.length)
function Form(props) {
  const [name, setName] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    if(name != ""){
      props.addTask(name);
      setName("");
    }
  }
  function handleChange(e) {
    setName(e.target.value);
  }
  return (
    <form onSubmit={handleSubmit}>
        <h2 className="label-wrapper">
          <label htmlFor="new-todo-input" className="label__lg">
            {
              todo_input_labels[todo_input_labels_index]
            }
          </label>
        </h2>
        <input
          type="text"
          id="new-todo-input"
          className="input input__lg"
          name="text"
          autoComplete="off"
          value={name}
          onChange={handleChange}
        />
        <button type="submit" className="btn btn__primary btn__lg">
          Add
        </button>
      </form>
  )
}
export default Form;